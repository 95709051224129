import { useMemo } from "preact/hooks";
import { useSignal } from "@preact/signals"; // Importiere useSignal von Preact Signals
import { isAuthenticatedSignal } from "../isAuthenticatedSignalPool.ts"; // Import the shared signal
import RestMethodBadge from "../../components/RestMethodBadge.tsx";
import { LockIconClosed, LockIconOpen } from "../../components/LockIcon.tsx";
import PanelLeftHeader from "./PanelLeftHeader.tsx";

interface Tag {
  name: string;
  description?: string;
}

interface ApiEndpointsListProps {
  paths: Record<string, any>;
  tags: Tag[];
  selectedPath: { value: string | null };
  selectedMethod: { value: string | null };
  onSelectionChange: (path: string | null, method: string | null) => void;
}

const getIcon = () => {
  return isAuthenticatedSignal.value ? <LockIconOpen /> : <LockIconClosed />;
};

export default function ApiEndpointsList({
  paths,
  tags,
  selectedPath,
  selectedMethod,
  onSelectionChange = () => {}, // Default no-op function to avoid errors
}: ApiEndpointsListProps) {
  const searchQuery = useSignal<string>(""); 
  const showProfiles = useSignal<boolean>(true); 

  // Use correct types for 'acc' and 'tag' in reduce
  const expandedGroups = useSignal<Record<string, boolean>>(
    tags.reduce((acc: Record<string, boolean>, tag: Tag) => {
      acc[tag.name] = true;
      return acc;
    }, {} as Record<string, boolean>)
  );

  const pathsByTag = useMemo(() => {
    return tags.reduce((acc: Record<string, [string, any][]>, tag: Tag) => {
      acc[tag.name] = Object.entries(paths).filter(([path, methods]) =>
        Object.values(methods).some((method: any) => method.tags?.includes(tag.name))
      );
      return acc;
    }, {} as Record<string, [string, any][]>);
  }, [paths, tags]);

  const toggleAllGroups = (expand: boolean) => {
    expandedGroups.value = tags.reduce((acc: Record<string, boolean>, tag: Tag) => {
      acc[tag.name] = expand;
      return acc;
    }, {} as Record<string, boolean>);
  };

  const filteredPathsByTag = useMemo(() => {
    return Object.entries(pathsByTag).reduce((acc: Record<string, [string, any][]>, [tagName, pathList]) => {
      const lowerSearchQuery = searchQuery.value.toLowerCase();

      const filteredPaths = pathList.filter(([path, methods]) => {
        const pathMatches = path.toLowerCase().includes(lowerSearchQuery);
        const methodMatches = Object.keys(methods).some((method) =>
          method.toLowerCase().includes(lowerSearchQuery)
        );

        const profileMatches =
          showProfiles.value &&
          tags
            .find((tag) => tag.name === tagName)
            ?.description?.toLowerCase()
            .includes(lowerSearchQuery);

        return (pathMatches || methodMatches) || profileMatches;
      });

      if (filteredPaths.length > 0) {
        acc[tagName] = filteredPaths;
      }

      return acc;
    }, {} as Record<string, [string, any][]>);
  }, [pathsByTag, searchQuery.value, showProfiles.value]);

  return (
    <div class="flex flex-col h-screen">
      <div class="sticky top-0 z-10 bg-white">
        <PanelLeftHeader
          searchQuery={searchQuery}
          onExpandAll={() => toggleAllGroups(true)}
          onCollapseAll={() => toggleAllGroups(false)}
          onSearch={(value) => (searchQuery.value = value)}
          showProfiles={showProfiles.value}
          onToggleProfiles={() => (showProfiles.value = !showProfiles.value)}
        />
      </div>

      <div class="flex-1 overflow-y-auto py-4 sm:py-2">
        {Object.keys(filteredPathsByTag).length === 0 ? (
          <p class="text-center text-sm">No endpoints found</p>
        ) : (
          tags.map((tag) =>
            filteredPathsByTag[tag.name] ? (
              <div key={tag.name} class="mb-4 sm:mb-2">
                <div
                  class="cursor-pointer text-sm sm:text-base font-bold flex justify-between items-center p-2 sm:p-1 bg-slate-300 hover:opacity-70 rounded"
                  onClick={() =>
                    (expandedGroups.value = {
                      ...expandedGroups.value,
                      [tag.name]: !expandedGroups.value[tag.name],
                    })
                  }
                >
                  <span>{tag.name}</span>
                  <span class="mr-2 md:mr-4">{expandedGroups.value[tag.name] ? "▲" : "▼"}</span>
                </div>

                {showProfiles.value && tag.description && (
                  <p
                    class="text-slate-700 mb-2 sm:mb-1 pl-2 text-sm sm:text-xs break-words"
                    dangerouslySetInnerHTML={{
                      __html: tag.description,
                    }}
                  ></p>
                )}

                {expandedGroups.value[tag.name] && (
                  <ul class="pl-4 sm:pl-2">
                    {filteredPathsByTag[tag.name]?.map(([path, methods]) =>
                      Object.keys(methods).map((method) => (
                        <li
                          key={method}
                          onClick={() => {
                            selectedPath.value = path;
                            selectedMethod.value = method;
                            onSelectionChange(path, method);
                          }}
                          class="cursor-pointer flex flex-col p-2 mb-2 sm:mb-1 border rounded-md hover:bg-slate-50 transition"
                        >
                          <div class="flex items-center font-bold text-base sm:text-sm mr-2">
                            <RestMethodBadge method={method} />
                            <span class="ml-2 flex-1 ">{path}</span>
                            {/* Get icon based on isAuthenticatedSignal */}
                            {methods[method].security && getIcon()}
                          </div>

                          {methods[method]?.summary && (
                            <p class="text-sm sm:text-xs text-gray-700 pl-1">
                              {methods[method].summary}
                            </p>
                          )}
                        </li>
                      ))
                    )}
                  </ul>
                )}
              </div>
            ) : null
          )
        )}
      </div>
    </div>
  );
}
